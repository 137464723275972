// import { configs } from "../config";
import config from 'config/config';
import XhrHttps from './XhrHttps';

class ArticleServices {
  domain = config.domain + 'article';

  add = async (data: any) => {
    let url = `${this.domain}/add`;
    // console.log('---Regis---');
    // console.log(data)
    let item = await XhrHttps.post(url, data);
    return item;
  };

  edit = async (data: any) => {
    let url = `${this.domain}/edit`;
    let item = await XhrHttps.post(url, data);
    return item;
  };

  get: any = async (data: any = null) => {
    let url = `${this.domain}/`;

    let items = await XhrHttps.get(url, { params: data });
    return items;
  };

  getById = async (id: any = null) => {
    let url = `${this.domain}/:id`;
    // console.log(data  )
    let items = await XhrHttps.get(url, { params: { id: id } });
    return items;
  };
  getBySlug = async (slug: string = null) => {
    let url = `${this.domain}/:slug`;
    // console.log(data  )
    let items = await XhrHttps.get(url, { params: { slug: slug } });
    return items;
  };
}
export default new ArticleServices();
