import React, { useState, useEffect, useCallback } from "react";
import "./Blog.scss";
import { Fade } from "react-reveal";
import ArticleServices from "services/ArticleServices";
import config from "config/config";
import Cover from "components/cover";
import { useParams } from "react-router-dom";
export default function BlogViews() {
  const article = useParams().article; // Lấy tham số từ URL
  const [items, setItems] = useState<any>([])
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    if (categories == null) getAllCategory();
    getItem()
  }, [categories]);

  const getAllCategory = async () => {
    var _items: any = localStorage.getItem('category');
    // console.log(_items)
    var list = JSON.parse(_items) || null
    // console.log( appname )
    if (!list) {
      const _list = await ArticleServices.get({
        searchParams: { type: 'category' },
        orderBy: 'parent_id ASC, id DESC',
        isDesc: true,
        limit: 200,
        off: 0
      });
      list = _list.data;
      localStorage.setItem('category', JSON.stringify(list))
    }

    // console.log(list)
    if (list) setCategories(list);
  };

  const getItem = useCallback(async () => {
    if (article === null) return null
    const _data = await ArticleServices.get({ searchParams: {} })
    var items: any = _data.data.filter((ele: any, idx: number) => ele.slug === article);
    if (items) {
      var data = items[0]
      const cate = categories?.find((cat: any) => cat.id === data.parent_id) || [];
      data.cate = cate;
      // console.log("==============")
      // console.log(data)
      // console.log("==============")
      setItems(data);

      document.title = data.name + ' | ' + document.title

      document.querySelectorAll('head meta').forEach(meta => {
        if (meta.getAttribute('name') === 'title' || meta.getAttribute('property') === 'og:title' || meta.getAttribute('property') === 'twitter:title')
          meta.setAttribute('content', data.name + ' | ' + meta.getAttribute('content'))

        if (meta.getAttribute('name') === 'description' || meta.getAttribute('property') === 'og:description' || meta.getAttribute('property') === 'twitter:description')
          meta.setAttribute('content', data?.summary?.replace(/(<([^>]+)>)/gi, ''))

        if (meta.getAttribute('property') === 'og:url' || meta.getAttribute('property') === 'twitter:url')
          meta.setAttribute('content', window.location.href)
      });

    }
  }, [article, categories])
  return (
    <Fade bottom duration={1000} distance="20px">
      {items && <Cover app={items} image={config.imageUrl + '/' + items.image} />}

      <Fade bottom duration={1000} distance="40px">
        <div className="greet-main" id="greeting">
          <div id={items?.slug + '-summary'} dangerouslySetInnerHTML={{ __html: items?.summary }} />

          {items?.description &&
            <div id={items?.slug} dangerouslySetInnerHTML={{ __html: items?.description }} />
          }
        </div>
      </Fade>
    </Fade>
  );
}
