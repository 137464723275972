import React from "react";
import "./Cover.scss";
import Url from "utils/Url";

const Cover = (props: any) => {
   const bg = props.image ? props.image : require('./../../assets/images/Almac-Gateway-Banner.jpg');
   const url = Url.site();

   return (<div className='cover' style={{ backgroundImage: `url(${bg})` }} >
      <h1>{props.app.name} </h1>
      <p>
         <a href={url}>{'Home'}</a>
         {props.app.cate?.name && <>
            {' > '}
            <a href={url + '/' + props.app.cate?.slug}>{props.app.cate?.name || ''}</a>
         </>}
         {' > '}
         <a href={window.location.href}>{props.app.name}</a>
      </p>
   </div>);
}
export default Cover;
