import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import { StyleProvider } from "contexts/StyleContext";
import React from "react";
import ScrollToTopButton from "../../containers/topbutton/Top";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useParams } from "react-router-dom";
import BlogViews from "containers/blog/BlogViews";
import Blog from "containers/blog/Blog";
// localStorage.removeItem('category')
const Blogs = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
  const article = useParams().article; // Lấy tham số từ URL

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <div className={isDark ? "dark-mode" : null}>
      <StyleProvider value={{ isDark: isDark, changeTheme: changeTheme }}>
        <>
          <Header />
          {article ? <BlogViews /> : <Blog limit={null} />}

          <Footer />
          <ScrollToTopButton />
        </>
      </StyleProvider>
    </div>
  );
};

export default Blogs;