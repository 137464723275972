import React from 'react';

interface PaginationProps {
   count: number; // Tổng số mục
   page: number; // Trang hiện tại
   rowsPerPage: number; // Số hàng mỗi trang
   onPageChange: (newPage: number) => void; // Hàm xử lý thay đổi trang
   onRowsPerPageChange: (newRowsPerPage: number) => void; // Hàm xử lý thay đổi số hàng mỗi trang
   rowsPerPageOptions?: number[]; // Các tùy chọn số hàng mỗi trang
}

const Pagination: React.FC<PaginationProps> = ({
   count,
   page,
   rowsPerPage,
   onPageChange,
   onRowsPerPageChange,
   rowsPerPageOptions = [5, 10, 25, 30],
}) => {
   const totalPages = Math.ceil(count / rowsPerPage);

      const handlePrevious = () => {
      if (page > 0) {
         onPageChange(page - 1);
      }
   };

   const handleNext = () => {
      if (page + 1 < totalPages) {
         onPageChange(page + 1);
      }
   };

   const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      onRowsPerPageChange(Number(event.target.value));
   };

   return (
      <div style={styles.container}>
         <div style={styles.rowsPerPage}>
            <span>Rows per page:</span>
            <select value={rowsPerPage} onChange={handleRowsPerPageChange} style={styles.select}>
               {rowsPerPageOptions.map((option) => (
                  <option key={option} value={option}>
                     {option}
                  </option>
               ))}
            </select>
         </div>
         <div style={styles.pagination}>
            <button onClick={handlePrevious} disabled={page === 0} style={styles.button}>
               &lt;
            </button>
            <span>
               Page {page + 1} of {totalPages}
            </span>
            <button onClick={handleNext} disabled={page + 1 >= totalPages} style={styles.button}>
               &gt;
            </button>
         </div>
      </div>
   );
};

const styles = {
   container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      // border: '1px solid #ccc',
      borderRadius: '4px',
      // backgroundColor: '#f9f9f9',
   },
   rowsPerPage: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
   },
   select: {
      padding: '5px',
      borderRadius: '4px',
      border: '1px solid #ccc',
   },
   pagination: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
   },
   button: {
      padding: '5px 10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      backgroundColor: '#fff',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
   },
};

export default Pagination;
