import React, {useEffect, useState} from "react";
import "./Main.scss";
import Header from "../../components/header/Header";
import Greeting from "../../containers/greeting/Greeting";
import Skills from "../../containers/skills/Skills";
import StackProgress from "../../containers/skillProgress/skillProgress";
import WorkExperience from "../../containers/workExperience/WorkExperience";
import Projects from "../../containers/projects/Projects";
import StartupProject from "../../containers/StartupProjects/StartupProject";
import Achievement from "../../containers/achievement/Achievement";
import Blog from "../../containers/blog/Blog";
import Footer from "../../components/footer/Footer";
import Talks from "../../containers/talks/Talks";
import Podcast from "../../containers/podcast/Podcast";
import Education from "../../containers/education/Education";
import ScrollToTopButton from "../../containers/topbutton/Top";
import Twitter from "../../containers/twitter-embed/twitter";
import Profile from "../../containers/profile/Profile";
import SplashScreen from "../../containers/splashScreen/SplashScreen";
import {splashScreen} from "../../portfolio";
import {StyleProvider} from "../../contexts/StyleContext";
import {useLocalStorage} from "../../hooks/useLocalStorage";

import AppMores from "../../containers/appMores/AppMores";
import Url from "utils/Url";
import { Item } from "services/ItemServices";

const Main = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
  const [isShowingSplashAnimation, setIsShowingSplashAnimation] = useState(Url.site() === window.location.href);

  useEffect(() => {
    // console.log( Url.site() + " === " + window.location.href )
    // if(Url.site() != window.location.href) {
    //   return setIsShowingSplashAnimation(false)
    // }
    getItem()
    if (splashScreen.enabled) {
      const splashTimer = setTimeout(
        () => setIsShowingSplashAnimation(false),
        splashScreen.duration
      );
      return () => {
        clearTimeout(splashTimer);
      };
    }
  }, []);
  
	const getItem = async () => {
		var items: any = await Item.get({ searchParams: {} })
    // localStorage.clear()
		localStorage.setItem('items', JSON.stringify(items) )
	}

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <div className={isDark ? "dark-mode" : null}>
      <StyleProvider value={{isDark: isDark, changeTheme: changeTheme}}>
        {isShowingSplashAnimation && splashScreen.enabled ? (
          <SplashScreen />
        ) : (
          <>
            <Header />
            <Greeting />
            <Skills />
            <StackProgress />
            <Education />
            <WorkExperience />
            <AppMores />
            <Projects />
            <StartupProject />
            <Achievement />
            <Blog limit={12}/>
            <Talks />
            <Twitter />
            <Podcast />
            <Profile />
            <Footer />
            <ScrollToTopButton />
          </>
        )}
      </StyleProvider>
    </div>
  );
};

export default Main;
