import React, { useState, useEffect, useContext } from "react";
import "./Blog.scss";
import BlogCard from "../../components/blogCard/BlogCard";
import { blogSection } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import ArticleServices from "services/ArticleServices";
import config from "config/config";
import { useParams } from "react-router-dom";
import Pagination from "components/pagination/Pagination";
import Url from "utils/Url";

export default function Blog({ limit }) {
  const { isDark } = useContext(StyleContext);
  const { category, article } = useParams(); // Lấy tham số từ URL

  const [items, setItems] = useState<any>([])
  const [params, setParams] = useState<any>({
    searchParams: { type: 'article' },
    orderBy: 'parent_id ASC, id DESC',
    isDesc: true,
    limit: limit ? limit : 20,
    off: 0
  });

  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [categories, setCategories] = useState(null);

  //Medium API returns blogs' content in HTML format. Below function extracts blogs' text content within paragraph tags
  const extractTextContent = (html: any) => {
    return typeof html === "string"
      ? html
        .split("p>")
        .filter(el => !el.includes(">"))
        .map(el => el.replace("</", ".").replace("<", ""))
        .join(" ")
      : NaN;
  }
  useEffect(() => {
    if (categories == null) getAllCategory();
    if (category && categories) {

      var _params = params;
      _params.searchParams.parent_id = categories.find((cat: any) => cat.slug === category).id || 0;
      setParams(_params);
    }
    getAll();
  }, [categories]);

  const getAll = async () => {
    const list = await ArticleServices.get(params);
    // console.log(list)
    if (list.data) {
      setItems(list.data);
      setTotal(list.total);
    }
  };

  const getAllCategory = async () => {
    var _items: any = localStorage.getItem('category');
    var list = null;
    try {
      list = JSON.parse(_items);
    } catch (e) {
      console.error("Error parsing JSON:", e);
      list = null; // Hoặc xử lý lỗi theo cách khác, chẳng hạn trả về một giá trị mặc định
    }
    // var list = _items ? JSON.parse(_items) : null
    // console.log( appname )
    if (!list) {
      const _list = await ArticleServices.get({
        searchParams: { type: 'category' },
        orderBy: 'parent_id ASC, id DESC',
        isDesc: true,
        limit: 200,
        off: 0
      });
      list = _list.data;
      localStorage.setItem('category', JSON.stringify(list))
    }

    console.log(list)
    if (list) setCategories(list);
  };
  const handlePageChange = (newPage: number): void => {
    setPage(newPage);
    params.off += params.limit * newPage - params.limit;
    setParams({ ...params, off: params.off });
    setPage(newPage);
    // console.log(newPage);
    // console.log(params);
    getAll();
  };

  const handleLimitChange = (newLimit: number): void => {
    var p = params;
    p.limit = newLimit;
    setParams(p)
    handlePageChange(1);
  };

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="blogs">
        <div className="blog-header">
          <a href={Url.site() + (limit || !category ?'/blogs' : '/'+category)}><h1 className="blog-header-text">{(limit || !category) ? blogSection.name : (categories?.find((cat: any) => cat.slug === category).name || '')}</h1></a>
          {limit && <a href={Url.site() + '/blogs'} className="blog-header-more">Views all</a>}
          <p
            className={
              isDark ? "dark-mode blog-subtitle" : "subTitle blog-subtitle"
            }
          >
            {blogSection.subtitle}
          </p>
        </div>
        <div className="blog-main-div">
          <div className="blog-text-div">
            {items && categories && items.map((blog: any, i) => {
              const cate = categories.find((cat: any) => cat.id === blog.parent_id) || [];
              return (
                <BlogCard
                  key={i}
                  isDark={isDark}
                  blog={{
                    url: Url.site() + '/' + cate.slug + '/' + blog?.slug || '',
                    image: `${config.thumbUrl}/${blog.image}`,
                    title: blog.name,
                    description: extractTextContent(blog.summary)
                  }}
                />
              );
            })}
          </div>
        </div>

        {!limit && !article && <Pagination
          count={total}
          page={page}
          rowsPerPage={params.limit}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />}
      </div>
    </Fade>
  );
}
