import Apps from "content/apps";
import Blogs from "content/blogs";
import Main from "content/main/Main";
import Payment from "content/payment";
import { createBrowserRouter } from "react-router-dom";

const genRouter: any = (lang: string = '') => {
   var path = lang ? `/${lang}` : ''
   return [
      {
         path: path + "/",
         element: <Main />,
      },
      {
         path: path + "/apps/:appname?",
         element: <Apps />,
      },
      {
         path: path + "/payment/:appname?",
         element: <Payment />
      },
      {
         path: path + "/blogs",
         element: <Blogs />
      },
      {
         path: path + "/:category",
         element: <Blogs />
      },
      {
         path: path + "/:category/:article",
         element: <Blogs />
      },
   ]
}
var routers = genRouter();
// routers = [ ...routers, genRouter('vi') ]
routers = routers.concat(genRouter('vi'))

export const router = createBrowserRouter(routers);




//  function ErrorBoundary() {
//    let error = useRouteError();
//    console.error(error);
//    // Uncaught ReferenceError: path is not defined
//    return <div>Dang!</div>;
//  }